import React from 'react';
import UserTransaction from './components/UserTransaction';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faPlayCircle, faTimes, faGrinStars, faMeh, faMehBlank, faFrown, faCircleNotch, faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons';

import './App.css';
library.add( faCheck, faTimes, faPlayCircle, faGrinStars, faMeh, faMehBlank, faFrown, faCircleNotch, faChevronRight, faChevronLeft);

function App() {
  return (
    <div className="App">
      <Container>
            <Row>
              <Col lg={{ span:8, offset: 2}} md={{ span: 10, offset: 1 }}>                               
                <UserTransaction />
              </Col>
            </Row>
      </Container>  
    </div>
  );
}

export default App;
